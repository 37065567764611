import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  const [queryParams, setQueryParams] = useState(
    new URLSearchParams(useLocation().search),
  );

  return queryParams;
};
