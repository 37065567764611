import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useClient } from '../../hooks/useClient';
import { FINLHeader } from './finl/FINLHeader';
import { JDHeader } from './jd/JDHeader';

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
}));

function Header() {
  const client = useClient();
  const classes = useStyles();
  return (
    <Box>
      {client.fascia === 'finl-web' && <FINLHeader />}
      {client.fascia === 'jdsp-web' && <JDHeader />}
      <Box className={classes.toolbar} />
    </Box>
  );
}

export default Header;
