import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../../../assets/jd-logo.svg';

const useStyles = makeStyles(theme => ({
  header: {
    alignItems: 'center',
    borderBottom: '1px solid lightgray',
    backgroundColor: 'black',
  },
}));

export const JDHeader = () => {
  const classes = useStyles();

  return (
    <AppBar color="transparent" elevation={0} className={classes.header}>
      <Toolbar>
        <img src={logo} alt="Site logo" />
      </Toolbar>
    </AppBar>
  );
};
