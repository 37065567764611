import React from 'react';
// import { createBrowserHistory } from 'history';
import { BrowserRouter, Route } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';
import Header from './components/header/Header';
import { NpsSurveyLoader } from './components//survey/NpsSurveyLoader';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import NpsSurveyProvider from './context/SurveyContext';
// const theme = createTheme({
//   typography: {
//     fontFamily: 'Knockout',
//   },
// });
// const defaultHistory = createBrowserHistory();

/**
 *
 * xs: 0
 * sm: 600
 * md: 960
 * lg: 1280
 * xl: 1920
 *
 */
// const useStyles = makeStyles(theme => ({
//   root: {
//     height: '100vh',
//   },
//   toolbar: theme.mixins.toolbar,
// }));

export const App = props => {
  //   const classes = useStyles();
  //   const appHistory = props.history || defaultHistory;

  return (
    <Grid container direction="column">
      <Grid item>
        <Header />
      </Grid>
      <Grid item container>
        <Grid item lg={4} md={3} sm={2} xs={1}>
          {/* <Box border={1} color="red">
            left
          </Box> */}
        </Grid>

        <Grid item lg={4} md={6} sm={8} xs={10}>
          <BrowserRouter>
            <Box border={0}>
              <NpsSurveyProvider>
                <Route path="/" exact component={NpsSurveyLoader} />
              </NpsSurveyProvider>
            </Box>
          </BrowserRouter>
        </Grid>

        <Grid item lg={4} md={3} sm={2} xs={1}>
          {/* <Box border={1} color="red">
            right
          </Box> */}
        </Grid>
      </Grid>
    </Grid>
  );
};
