export default function makeHandleRequest({
  axios,
  handleSuccess,
  handleError,
}) {
  return async function requestHandler(httpRequest) {
    switch (httpRequest.method) {
      case 'POST':
        return postRequest(httpRequest);
      case 'GET':
        return getRequest(httpRequest);
      case 'PUT':
        return putRequest(httpRequest);
      default:
        return handleError(httpRequest, {});
    }
  };

  async function postRequest(httpRequest) {
    return axios
      .post(httpRequest.path, httpRequest.body, {
        params: httpRequest.params,
      })
      .then(response => handleSuccess(response))
      .catch(error => handleError(httpRequest, error));
  }

  async function getRequest(httpRequest) {
    return axios
      .get(httpRequest.path, {
        params: httpRequest.params,
      })
      .then(response => handleSuccess(response))
      .catch(error => handleError(httpRequest, error));
  }

  async function putRequest(httpRequest) {
    return axios
      .put(httpRequest.path, httpRequest.body, {
        params: httpRequest.params,
      })
      .then(response => handleSuccess(response))
      .catch(error => handleError(httpRequest, error));
  }
}
