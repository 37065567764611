import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../../../assets/fnl-logo.svg';

const useStyles = makeStyles(theme => ({
  header: {
    alignItems: 'center',
    borderBottom: '1px solid #ACACAC',
    background: '#F2F2F2',
    // height: '120px',
    // height: '88px',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));

export const FINLHeader = () => {
  const classes = useStyles();

  return (
    <AppBar elevation={0} className={classes.header}>
      <Toolbar>
        <img src={logo} alt="Site logo" />
      </Toolbar>
    </AppBar>
  );
};
