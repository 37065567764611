import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { RatingAnswer } from './RatingAnswer';

const useStyles = makeStyles(theme => ({
  primaryText: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#000000',
  },
  secondaryText: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#666666',
  },
  divider: {
    backgroundColor: '#ACACAC',
  },
}));

const PrimaryText = ({ text }) => {
  const classes = useStyles();
  return (
    <Typography component="div" className={classes.primaryText}>
      <Box>{text}</Box>
    </Typography>
  );
};

const SubText = ({ text }) => {
  const classes = useStyles();
  return (
    <Typography component="div" className={classes.secondaryText}>
      <Box>{text}</Box>
    </Typography>
  );
};

export const RatingQuestion = ({ onAnswerChange, text, options, answer }) => {
  const classes = useStyles();

  const handleChange = async option => {
    onAnswerChange(option);
  };

  return (
    <Box mb={3} border={0}>
      <Box mb={3}>
        <PrimaryText text={text} />
        <SubText text="Select a rating from 1 to 5." />
      </Box>

      <Box mb={3}>
        <RatingAnswer onClick={handleChange} value={answer?.option?.value} />
    </Box>
      
      <Divider className={classes.divider} />
    </Box>
  );
};
