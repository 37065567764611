import makeHandleRequest from '../http-helpers/handle-request';
import makeStartNpsSurvey from './makeStartNpsSurvey';
import makeUpdateSurveyAnswer from './makeUpdateSurveyAnswer';
import makeSubmitSurvey from './makeSubmitSurvey';
import axios from 'axios';
import makeHttpSuccess from '../http-helpers/http-success';
import makeHttpError from '../http-helpers/http-error';
import makeHandleSuccess from '../http-helpers/handle-success';
import makeHandleError from '../http-helpers/handle-error';

const handleSuccess = makeHandleSuccess({ makeHttpSuccess });
const handleError = makeHandleError({ makeHttpError });

const requestHandler = makeHandleRequest({
  axios,
  handleSuccess,
  handleError,
});

const startNpsSurvey = makeStartNpsSurvey({ requestHandler });
const updateSurveyAnswer = makeUpdateSurveyAnswer({ requestHandler });
const submitSurvey = makeSubmitSurvey({ requestHandler });

const npsSurveyEndpoint = Object.freeze({
  startNpsSurvey,
  updateSurveyAnswer,
  submitSurvey,
});

export default npsSurveyEndpoint;
