import React from 'react';
import { useClient } from '../../hooks/useClient';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useFinlStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    backgroundColor: '#008FDB',
    '&:hover': {
      backgroundColor: 'white',
      color: '#008FDB',
      border: '1px solid #008FDB',
    },
    padding: '10px',
    width: '100%',
  },
}));

const useJDStyles = makeStyles(theme => ({
  root: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '14px',
    backgroundColor: '#FFEE00',
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFEE00',
    },
    padding: '10px',
    width: '100%',
  },
}));

export const SubmitButton = ({ onSubmit }) => {
  const client = useClient();
  const finlStyles = useFinlStyles();
  const jdStyles = useJDStyles();
  const classes = client.fascia === 'finl-web' ? finlStyles : jdStyles;
  return (
    <Button className={classes.root} onClick={onSubmit}>
      Submit
    </Button>
  );
};
