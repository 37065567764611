import React from 'react';
import Box from '@material-ui/core/Box';
import { useClient } from '../../hooks/useClient';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useFinlStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    border: '1px solid #008FDB',
    backgroundColor: '#008FDB',
    '&:hover': {
      backgroundColor: 'white',
      color: '#008FDB',
      border: '1px solid #008FDB',
    },
    padding: '10px',
    width: '100%',
  },
}));

const useJDStyles = makeStyles(theme => ({
  root: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '14px',
    backgroundColor: '#FFEE00',
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFEE00',
    },
    padding: '10px',
    width: '100%',
  },
}));

export const LinkButton = ({ title, linkTo }) => {
  const client = useClient();
  const finlStyles = useFinlStyles();
  const jdStyles = useJDStyles();
  const classes = client.fascia === 'finl-web' ? finlStyles : jdStyles;

  return (
    <Box>
      <a href={linkTo} style={{ textDecoration: 'none' }}>
        <Button className={classes.root}>{title}</Button>
      </a>
    </Box>
  );
};
