import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

export const Comments = ({ onChange, value }) => {

  return (
    <Box>
      <Typography>Please add any additional comments.</Typography>
      <Box mt={1}>
        <TextField
          value={value}
          placeholder="Type any additional comments..."
          multiline
          rows={6}
          inputProps={{maxLength: 400}}
          variant="outlined"
          fullWidth
          onChange={e => onChange(e.target.value)}
        />
      </Box>
    </Box>
  );
};
