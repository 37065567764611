import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useClient } from '../../../hooks/useClient';

const useFinlStyles = makeStyles(theme => ({
  option: {
    cursor: 'pointer',
    background: props => (props.selected ? '#666666' : 'white'),
    width: props => (props.width ? props.width : '48px'),
    height: props => (props.width ? props.width : '48px'),
    borderRadius: '50%',
    border: '1px solid #666666',
    color: props => (props.selected ? 'white' : 'black'),
    '&:hover': {
      background: '#666666',
      color: 'white',
    },
  },
  optionInnerLabel: {
    textAlign: 'center',
    lineHeight: '30px',
    fontSize: '28px',
    fontFamily: 'Knockout',
    textTransform: 'uppercase',
  },
  optionOuterLabel: {
    textAlign: 'center',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: '13px',
    lighHeight: '18px',
    textTransform: 'uppercase',
    color: 'black',
  },
}));

const useJDStyles = makeStyles(theme => ({
  option: {
    cursor: 'pointer',
    background: props => (props.selected ? 'black' : 'white'),
    width: props => (props.width ? props.width : '48px'),
    height: props => (props.width ? props.width : '48px'),
    borderRadius: '50%',
    border: '1px solid black',
    color: props => (props.selected ? 'white' : 'black'),
    '&:hover': {
      background: 'black',
      color: 'white',
    },
  },
  optionInnerLabel: {
    textAlign: 'center',
    lineHeight: '30px',
    fontSize: '28px',
    fontFamily: 'Knockout',
    textTransform: 'uppercase',
  },
  optionOuterLabel: {
    textAlign: 'center',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: '13px',
    lighHeight: '18px',
    textTransform: 'uppercase',
    color: 'black',
  },
}));

const MakeOption = ({ classes, label }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box
        className={classes.option}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className={classes.optionInnerLabel}>{label}</Typography>
      </Box>
    </Box>
  );
};

export const RatingOption = ({ value, label, onClick, selected }) => {
  const client = useClient();
  const finlStyles = useFinlStyles({ selected });
  const jdStyles = useJDStyles({ selected });
  const classes = client.fascia === 'finl-web' ? finlStyles : jdStyles;
  return (
    <Box border={0} onClick={() => onClick(value)}>
      <MakeOption label={value} selected={selected} classes={classes} />
      <Typography className={classes.optionOuterLabel} component="div">
        <Box>{label}</Box>
      </Typography>
    </Box>
  );
};
