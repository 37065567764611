export default function makeUpdateSurveyAnswer({ requestHandler }) {
  return async function updateSurveyAnswer(token, surveyId, questionId, value) {
    const request = {
      path: `/api/v1/npsSurveys/${surveyId}/answer`,
      method: 'PUT',
      params: {
        token: token,
      },
      body: {
        questionId,
        value,
      },
    };

    const response = await requestHandler(request);
    return response;
  };
}
