export default function makeHandleError({ makeHttpError }) {
  return function errorHandler(request, error) {
    if (error?.response) {
      return makeHttpError({
        statusCode: 'xhr_error',
        errorMessage: error.response.data.error
          ? error.response.data.error
          : error.response.data,
      });
    } else if (error?.request) {
      return makeHttpError({
        statusCode: 'unknown1',
        errorMessage: `${request.method} method has unknown error1.`,
      });
    } else {
      return makeHttpError({
        statusCode: 'unkown2',
        errorMessage: `${request.method} method has unknown error2.`,
      });
    }
  };
}
