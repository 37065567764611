import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  primaryText: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#000000',
  },
  secondaryText: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#666666',
  },
  divider: {
    backgroundColor: '#ACACAC',
  },
}));

const PrimaryText = ({ text }) => {
  const classes = useStyles();
  return (
    <Typography component="div" className={classes.primaryText}>
      <Box>{text}</Box>
    </Typography>
  );
};

const SubText = ({ text }) => {
  const classes = useStyles();
  return (
    <Typography component="div" className={classes.secondaryText}>
      <Box>{text}</Box>
    </Typography>
  );
};

export const MultipleChoiceQuestion = ({ onAnswerChange, text, options, answer }) => {
    const classes = useStyles();

    const [selected, setSelected] = useState([]);

    const handleChange = async e => {
            const value = e.target.value;
            const checked = e.target.checked;

            if(checked) {
                const newState = [ ...selected, value];
                setSelected(newState);
                onAnswerChange(newState);
            } else {
                const newState = selected.filter(v => v !== value);
                setSelected(newState);
                onAnswerChange(newState);
            }
    };


    const isChecked = ({value}) => {

       return answer?.choices?.map(choice => choice.value).includes(value);
  }

  return (
    <Box mb={3} border={0}>
        <Box mb={3}>
            <PrimaryText text={text} />
            <SubText text="Select all that apply" />
        </Box>

        <Box mb={3}>
            <Box ml={3}>
                {
                    options.map( (option, index) => {
                        return (
                            <Grid item key={index}>
                                <FormControlLabel
                                    control={ 
                                                <Checkbox 
                                                    color="primary" 
                                                    onChange={handleChange} 
                                                    value={option.value}
                                                    checked={isChecked(option)}
                                                /> 
                                            }
                                    label={<Box fontSize={14}>{option.label}</Box>}
                                />
                            </Grid>
                        )
                    })
                }
            </Box>
                    
        </Box>
      
      <Divider className={classes.divider} />
    </Box>
  );
};
