export const useClient = () => {
  let client = '';
  let prod = true;
  let env = 'prod';

  if (window.location) {
    const url = window.location;
    if (url.host.includes('finishline')) client = 'finl-web';
    if (url.host.includes('jdsports')) client = 'jdsp-web';
    if (url.host.includes('localhost')) client = 'finl-web';

    if (url.host.includes('localhost')) {
      prod = false;
      env = 'localhost';
    }
    if (url.host.includes('dev')) {
      prod = false;
      env = 'dev';
    }
    if (url.host.includes('qa')) {
      prod = false;
      env = 'qa';
    }
    if (url.host.includes('stage')) {
      prod = false;
      env = 'stage';
    }
  }

  return {
    fascia: client,
    prod,
    env,
  };
};
