export default function makeSubmitSurvey({ requestHandler }) {
  return async function submitSurvey(token, surveyId, comments) {
    const response = await requestHandler({
      path: `/api/v1/npsSurveys/${surveyId}`,
      method: 'POST',
      params: {
        token: token,
      },
      body: {
        comments,
      },
    });
    return response;
  };
}
