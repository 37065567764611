import React, { useContext, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import TokenContext from '../../context/TokenContext';
import { SurveyContext } from '../../context/SurveyContext';
import { FreeFormQuestion } from '../question/freeform/FreeFormQuestion';
import { RatingQuestion } from '../question/rating/RatingQuestion';
import { MultipleChoiceQuestion } from '../question/multiplechoice/MultipleChoiceQuestion';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Comments } from '../comments/Comments';
import { SubmitButton } from '../button/SubmitButton';
import { SubmitSuccessPage } from './SubmitSuccessPage';

const useStyles = makeStyles(theme => ({
  title: {
    // fontFamily: 'Knockout',
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: 'bold',
    /* identical to box height, or 107% */
    textAlign: 'center',
    // textTransform: 'uppercase',

    color: '#000000',
  },
}));

export const NpsSurvey = props => {
  const classes = useStyles();
  const token = useContext(TokenContext);
  const { npsSurvey, update, submit } = useContext(SurveyContext);
  const [comments, setComments] = useState(npsSurvey.comments || '');

  const handleAnswerChange = async (question, option) => {
    await update(token, npsSurvey.surveyId, question.questionId, option);
  };

  const handleSubmitSurvey = async () => {
    const submitResponse = await submit(comments);
    console.log('submit response: ', submitResponse);
  };

  const renderSubmitSuccess = () => {
    return <SubmitSuccessPage />;
  };

  const areCommentsSupported = useMemo(() =>
      !(npsSurvey && npsSurvey.commentsUnsupported),
    [npsSurvey, npsSurvey.commentsUnsupported]);

  const renderSurvey = () => {

    return (
      <Grid container direction='column'>
        <Grid item xs={12}>
          <Box mt={6} mb={6}>
            <Typography className={classes.title}>
              {npsSurvey?.title}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {
            npsSurvey?.questions.map((question, index) => {

              const answer = npsSurvey?.answers.find(answer => {
                return answer.question.questionId === question.questionId;
              });

              const questionType = question.responseOption?.responseType;

              return (
                <Box key={index}>
                  {
                    questionType === 'FREEFORM' && (
                      <FreeFormQuestion
                        onChange={value => handleAnswerChange(question, value)}
                        text={question.text}
                        answer={answer}
                      />
                    )
                  }

                  {
                    questionType === 'RATING' && (
                      <RatingQuestion
                        onAnswerChange={option => handleAnswerChange(question, option)}
                        text={question.text}
                        options={question.responseOption.choices}
                        answer={answer}
                      />
                    )
                  }

                  {
                    questionType === 'MULTIPLE_CHOICE' && (
                      <MultipleChoiceQuestion
                        onAnswerChange={option => handleAnswerChange(question, option)}
                        text={question.text}
                        options={question.responseOption.choices}
                        answer={answer}
                      />
                    )
                  }
                </Box>

              );
            })
          }
        </Grid>

        {areCommentsSupported &&
          <Grid item xs={12}>
            <Comments
              value={comments}
              onChange={(c) => {
                setComments(c);
              }}
            />
          </Grid>
        }

        <Grid item xs={12}>
          <Box mt={6}>
            <SubmitButton onSubmit={() => handleSubmitSurvey()} />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {npsSurvey.completed && renderSubmitSuccess()}
      {!npsSurvey.completed && renderSurvey()}
    </>
  );
};
