export default function makeStartNpsSurvey({ requestHandler }) {
  return async function postStartNpsSurvey(token, responseId, value) {
    const response = await requestHandler({
      path: '/api/v1/npsSurveys',
      method: 'POST',
      params: {
        token: token,
        response: responseId,
        value: value,
      },
    });

    return response;
  };
}
