import { useEffect, useState } from 'react';
import { useQuery } from '../../hooks/useQuery';
import npsSurveyEndpoint from '.';
import _ from 'lodash';
import { useLogger } from '../../hooks/useLogger';

export const useNpsSurveyApi = () => {
  const [npsSurvey, setNpsSurvey] = useState();
  const [token, setToken] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState();
  const queryParams = useQuery();

  const [logger] = useLogger();

  const update = async (token, surveyId, questionId, value) => {

    const response = await npsSurveyEndpoint.updateSurveyAnswer(
      token,
      surveyId,
      questionId,
      Array.isArray(value) ? value : [ value ],
    );
    handleResponse(response);
  };

  const submit = async comments => {
    const response = await npsSurveyEndpoint.submitSurvey(
      token,
      npsSurvey.surveyId,
      comments,
    );
    handleResponse(response);
  };

  const handleResponse = response => {
    setLoading(false);

    switch (response.statusCode) {
      case 'success':
        setNpsSurvey(response.data);
        break;
      case 'failure':
        setErrorMsg(getFirstErrorMessage(response));
        break;
      case 'error':
        setErrorMsg(getFirstErrorMessage(response));
        break;
      default:
        setErrorMsg('Hitting default error');
        setErrorMsg(response.errorMessage);
        break;
    }
  };

  const getFirstErrorMessage = response => {
    const keys = _.keys(response.data);
    const errors = _.map(keys, key => response.data[key]);
    if (errors) {
      return errors[0];
    }
  };

  useEffect(() => {
    logger.debug('running effect');
    const token = queryParams.get('token');
    const responseId = queryParams.get('response');
    const value = queryParams.get('value');

    async function loadSurvey() {
      logger.debug('Loading survey with token: ', token);
      setToken(token);
      setLoading(true);

      const response = await npsSurveyEndpoint.startNpsSurvey(
        token,
        responseId,
        value,
      );

      handleResponse(response);
    }

    if (token !== undefined) {
      loadSurvey();
    }
  }, [queryParams]);

  return [npsSurvey, loading, errorMsg, update, submit];
};
