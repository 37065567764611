import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

ReactDOM.render(
  <StylesProvider generateClassName={generateClassName} injectFirst>
    <App />
  </StylesProvider>,
  document.getElementById('root'),
);
