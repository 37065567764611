import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { LinkButton } from '../button/LinkButton';
import { useClient } from '../../hooks/useClient';

export const ErrorPage = ({ errorMessage }) => {
  const client = useClient();

  const buttonTitle =
    client.fascia === 'finl-web' ? 'go to finish line' : 'go to jd sports';

  const link =
    client.fascia === 'finl-web'
      ? 'https://www.finishline.com'
      : 'https://www.jdsports.com';

  return (
    <Grid container direction="column" spacing={6}>
      <Grid item xs={12}>
        <Typography component="div">
          <Box
            display="flex"
            justifyContent="center"
            mt={6}
            fontWeight="fontWeightBold"
            fontSize={20}
            color={'info.secondary'}
          >
            The link to this survey is no longer valid.
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LinkButton title={buttonTitle} linkTo={link} />
      </Grid>
      {!client.prod && (
        <Grid item xs={12}>
          <Typography component="div">
            <Box
              display="flex"
              justifyContent="center"
              mt={6}
              fontWeight="fontWeightBold"
              fontSize={20}
              color={'error.main'}
            >
              {errorMessage}
            </Box>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
