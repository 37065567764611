import React from 'react';
import { useNpsSurveyApi } from '../api/nps-api/useNpsSurveyApi';

export const SurveyContext = React.createContext();

const NpsSurveyProvider = props => {
  const [npsSurvey, loading, errorMsg, update, submit] = useNpsSurveyApi();

  return (
    <SurveyContext.Provider
      value={{ npsSurvey, update, submit, loading, errorMsg }}
    >
      {props.children}
    </SurveyContext.Provider>
  );
};
export default NpsSurveyProvider;
