import React from 'react';
import Grid from '@material-ui/core/Grid';
import { RatingOption } from './RatingOption';

export const RatingAnswer = ({ options, onClick, value }) => {
  return (
    <Grid container justifyContent="space-between" alignItems="flex-start">
      {options.map((option, index) => (
        <Grid item key={index}>
          <RatingOption
            {...option}
            onClick={onClick}
            selected={option.value === `${value}`}
          />
        </Grid>
      ))}
    </Grid>
  );
};

RatingAnswer.defaultProps = {
  options: [
    { value: '1', label: 'POOR' },
    { value: '2', label: '' },
    { value: '3', label: 'NEUTRAL' },
    { value: '4', label: '' },
    { value: '5', label: 'GREAT' },
  ],
};
