import { useClient } from './useClient';

export const useLogger = () => {
  let client = useClient();

  const logger = {
    debug: msg => {
      if (client.env !== 'prod') {
        console.log(`${new Date()} DEBUG ${client.env}] : ${msg}`);
      }
    },
    error: msg => {
      if (client.env !== 'prod') {
        console.error(`[${client.env}] - ${msg}`);
      }
    },
    trace: msg => {
      if (client.env !== 'prod') {
        console.log(`[${client.env}] - ${msg}`);
      }
    },
  };
  return [logger];
};
