import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { LinkButton } from '../button/LinkButton';
import { useClient } from '../../hooks/useClient';

export const SubmitSuccessPage = () => {
  const client = useClient();

  const buttonTitle =
    client.fascia === 'finl-web' ? 'go to finish line' : 'go to jd sports';

  const link =
    client.fascia === 'finl-web'
      ? 'https://www.finishline.com'
      : 'https://www.jdsports.com';

  const handleLoadLink = () => {};

  return (
    <Grid container direction="column" spacing={6}>
      <Grid item xs={12}>
        <Typography component="div">
          <Box
            display="flex"
            justifyContent="center"
            mt={6}
            fontWeight="fontWeightBold"
            fontSize={20}
            color={'info.secondary'}
          >
            Thank you! The feedback you provided is important as we continue to meet your needs.
            <br />
            Mark Roper, VP of Customer Experience
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LinkButton title={buttonTitle} linkTo={link} />
      </Grid>
    </Grid>
  );
};
