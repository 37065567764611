import React, { useContext } from 'react';
import { NpsSurvey } from './NpsSurvey';
import { useQuery } from '../../hooks/useQuery';
import { SurveyContext } from '../../context/SurveyContext';
import TokenContext from '../../context/TokenContext';
import { ErrorPage } from './ErrorPage';

export const NpsSurveyLoader = () => {
  const queryParams = useQuery();
  const token = queryParams.get('token');
  const { npsSurvey, errorMsg } = useContext(SurveyContext);

  console.log('NpsSurveyLoader rendering survey with: ', npsSurvey);
  return (
    <>
      {errorMsg && <ErrorPage errorMessage={errorMsg} />}
      <TokenContext.Provider value={token}>
        {npsSurvey && <NpsSurvey />}
      </TokenContext.Provider>
    </>
  );
};
